
import { defineComponent, getCurrentInstance, onMounted } from "vue";
import { _Client } from "@/api-client";
import { GetAuthAccessRep } from "@/api-client/client";
import router, { asyncRoutes, constantRoutes } from "@/router";
import { RouteRecordRaw, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "ant-design-vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default defineComponent({
  setup() {
    const store = useStore();
    const { push, currentRoute } = useRouter();
    const internalInstance = getCurrentInstance();

    onMounted(async () => {
      SetUser();
      let acquireToken = await SetToken();
      if (acquireToken) {
        await GetPermissionsAndSetRouter();
      }
    });

    function SetUser() {
      const masl = internalInstance!.appContext.config.globalProperties.$msal;
      cookies.set(
        "user",
        JSON.stringify(
          masl.obj.getAllAccounts()[0]
        )
      );
    }

    async function SetToken() {
      const msal = internalInstance!.appContext.config.globalProperties.$msal;
      let acquireToken = await msal.getTokenRedirect();
      if (acquireToken) {
        cookies.set("accessToken", acquireToken.accessToken);
      } else {
        await push({ path: "/login" });
      }
      return acquireToken;
    }

    async function GetPermissionsAndSetRouter() {
      let permissions = await _Client.authClient.getAuthAccess();
      if (permissions.length == 0) {
        Modal.warning({
          title: "Login Error",
          content: "This user does not have permission to access.",
          okText: "LogOut",
          onOk: () => {
            internalInstance!.appContext.config.globalProperties.$msal.signOut();
          },
        });
      } else {
        store.commit("SET_PERMISSION", permissions);
        let routerList = GeneratePageAndRoutes(permissions);
        routerList.forEach((route: any) => {
          router.addRoute(route);
        });
        if (currentRoute.value.redirectedFrom) {
          await push({ ...currentRoute.value.redirectedFrom, replace: true });
        } else {
          await push({ path: "/", replace: true });
        }
      }
    }

    function GeneratePageAndRoutes(permissions: GetAuthAccessRep[]) {
      let accessedRoutes;
      const pagePermission: string[] = permissions.map(
        (permission: GetAuthAccessRep) => {
          return permission.pageName!;
        }
      );
      accessedRoutes = filterAsyncRoutes(asyncRoutes, pagePermission);
      // 有children
      accessedRoutes = accessedRoutes.filter((route: any) => {
        return route.children && route.children.length > 0;
      });
      let displayPages = decorationDisplayPage(accessedRoutes);
      store.commit("SET_DISPLAY_PAGES", displayPages);
      // 没有该路由返回404
      let notFoundPage = {
        path: '/:path(.*)*',
        redirect: '/404',
        meta: { hidden: true }
      } as RouteRecordRaw
      return constantRoutes.concat([...accessedRoutes, notFoundPage]);
    }

    function hasPermission(pagePermissions: string[], route: RouteRecordRaw) {
      if (route.meta && route.meta.permissionPageName) {
        return pagePermissions.includes(route.meta.permissionPageName);
      } else {
        if (route.children) return true;
        return false;
      }
    }

    function filterAsyncRoutes(routes: any, pagePermissions: string[]) {
      const res: RouteRecordRaw[] = [];
      routes.forEach((route: RouteRecordRaw) => {
        const r = { ...route };
        if (hasPermission(pagePermissions, r)) {
          if (r.children) {
            r.children = filterAsyncRoutes(r.children, pagePermissions);
          }
          res.push(r);
        }
      });
      return res;
    }

    function decorationDisplayPage(pages: RouteRecordRaw[]) {
      const res: RouteRecordRaw[] = [];
      pages.forEach((page) => {
        let item = { ...page };
        item.children = item.children?.filter((x) => !x.meta?.hidden);
        res.push(item);
      });
      return res;
    }
  },
});
